import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

export const LightMode = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "e19v3ao11"
} : {
  target: "e19v3ao11",
  label: "LightMode"
})(process.env.NODE_ENV === "production" ? {
  name: "r71woa",
  styles: "display:block;:is([data-theme=\"dark\"] &){display:none;}"
} : {
  name: "r71woa",
  styles: "display:block;:is([data-theme=\"dark\"] &){display:none;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvVGhlbWVNb2RlRGlzcGxheS9UaGVtZU1vZGVEaXNwbGF5LnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFFeUIiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC9zcmMvY29tcG9uZW50cy9UaGVtZU1vZGVEaXNwbGF5L1RoZW1lTW9kZURpc3BsYXkudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHR3IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuZXhwb3J0IGNvbnN0IExpZ2h0TW9kZSA9IHR3LmRpdmBcbiAgICBibG9jayBkYXJrOmhpZGRlblxuYFxuXG5leHBvcnQgY29uc3QgRGFya01vZGUgPSB0dy5kaXZgXG4gICAgaGlkZGVuIGRhcms6YmxvY2tcbmBcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});
export const DarkMode = _styled2("div", process.env.NODE_ENV === "production" ? {
  target: "e19v3ao10"
} : {
  target: "e19v3ao10",
  label: "DarkMode"
})(process.env.NODE_ENV === "production" ? {
  name: "bmfni4",
  styles: "display:none;:is([data-theme=\"dark\"] &){display:block;}"
} : {
  name: "bmfni4",
  styles: "display:none;:is([data-theme=\"dark\"] &){display:block;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL2NvbXBvbmVudHMvVGhlbWVNb2RlRGlzcGxheS9UaGVtZU1vZGVEaXNwbGF5LnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFNd0IiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC9zcmMvY29tcG9uZW50cy9UaGVtZU1vZGVEaXNwbGF5L1RoZW1lTW9kZURpc3BsYXkudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHR3IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuZXhwb3J0IGNvbnN0IExpZ2h0TW9kZSA9IHR3LmRpdmBcbiAgICBibG9jayBkYXJrOmhpZGRlblxuYFxuXG5leHBvcnQgY29uc3QgRGFya01vZGUgPSB0dy5kaXZgXG4gICAgaGlkZGVuIGRhcms6YmxvY2tcbmBcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});