import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { defaultLanguage } from "@config/i18n";
import Home from "@modules/Home";
export const getStaticProps = async ({
  locale
}: {
  locale: string;
}) => ({
  props: { ...(await serverSideTranslations(locale || defaultLanguage, ["components", "common", "pages", "home"]))
  }
});
export default Home;