import _styled2 from "@emotion/styled/base";

function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }

import Head from "@components/Head";
import ExplorePopularCrypto from "./components/ExplorePopularCrypto";
import Features from "./components/Features";
import Hero from "./components/Hero";
import OfficialPartners from "./components/OfficialPartners";
import ReadyToStart from "./components/ReadyToStart";
import StartInvesting from "./components/StartInvesting";
import WhyCrypto from "./components/WhyCrypto";
import WhyReku from "./components/WhyReku";

const Wrapper = _styled2("main", process.env.NODE_ENV === "production" ? {
  target: "ez2b4uf0"
} : {
  target: "ez2b4uf0",
  label: "Wrapper"
})(process.env.NODE_ENV === "production" ? {
  name: "3wj2uj",
  styles: "display:flex;flex-direction:column;--tw-bg-opacity:1;background-color:rgba(var(--colors-background), var(--tw-bg-opacity));padding-bottom:17px;padding-top:0px;:is([data-theme=\"dark\"] &){--tw-bg-opacity:1;background-color:rgba(var(--colors-dark-background), var(--tw-bg-opacity));}@media (min-width: 1280px){padding-top:76px;}"
} : {
  name: "3wj2uj",
  styles: "display:flex;flex-direction:column;--tw-bg-opacity:1;background-color:rgba(var(--colors-background), var(--tw-bg-opacity));padding-bottom:17px;padding-top:0px;:is([data-theme=\"dark\"] &){--tw-bg-opacity:1;background-color:rgba(var(--colors-dark-background), var(--tw-bg-opacity));}@media (min-width: 1280px){padding-top:76px;}",
  map: "/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIi92ZXJjZWwvcGF0aDAvc3JjL21vZHVsZXMvSG9tZS9Ib21lLnRzeCJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFhZ0IiLCJmaWxlIjoiL3ZlcmNlbC9wYXRoMC9zcmMvbW9kdWxlcy9Ib21lL0hvbWUudHN4Iiwic291cmNlc0NvbnRlbnQiOlsiaW1wb3J0IHR3IGZyb20gXCJ0d2luLm1hY3JvXCJcblxuaW1wb3J0IEhlYWQgZnJvbSBcIkBjb21wb25lbnRzL0hlYWRcIlxuXG5pbXBvcnQgRXhwbG9yZVBvcHVsYXJDcnlwdG8gZnJvbSBcIi4vY29tcG9uZW50cy9FeHBsb3JlUG9wdWxhckNyeXB0b1wiXG5pbXBvcnQgRmVhdHVyZXMgZnJvbSBcIi4vY29tcG9uZW50cy9GZWF0dXJlc1wiXG5pbXBvcnQgSGVybyBmcm9tIFwiLi9jb21wb25lbnRzL0hlcm9cIlxuaW1wb3J0IE9mZmljaWFsUGFydG5lcnMgZnJvbSBcIi4vY29tcG9uZW50cy9PZmZpY2lhbFBhcnRuZXJzXCJcbmltcG9ydCBSZWFkeVRvU3RhcnQgZnJvbSBcIi4vY29tcG9uZW50cy9SZWFkeVRvU3RhcnRcIlxuaW1wb3J0IFN0YXJ0SW52ZXN0aW5nIGZyb20gXCIuL2NvbXBvbmVudHMvU3RhcnRJbnZlc3RpbmdcIlxuaW1wb3J0IFdoeUNyeXB0byBmcm9tIFwiLi9jb21wb25lbnRzL1doeUNyeXB0b1wiXG5pbXBvcnQgV2h5UmVrdSBmcm9tIFwiLi9jb21wb25lbnRzL1doeVJla3VcIlxuXG5jb25zdCBXcmFwcGVyID0gdHcubWFpbmBcbiAgICBiZy1iYWNrZ3JvdW5kIGRhcms6YmctZGFyay1iYWNrZ3JvdW5kXG4gICAgZmxleCBmbGV4LWNvbFxuICAgIHhsOnB0LVs3NnB4XSBwdC0wIHBiLVsxN3B4XVxuYFxuXG5jb25zdCBIb21lID0gKCkgPT4gKFxuICAgIDxXcmFwcGVyPlxuICAgICAgICA8SGVhZCAvPlxuICAgICAgICA8SGVybyAvPlxuICAgICAgICA8V2h5UmVrdSAvPlxuICAgICAgICA8V2h5Q3J5cHRvIC8+XG4gICAgICAgIDxGZWF0dXJlcyAvPlxuICAgICAgICA8RXhwbG9yZVBvcHVsYXJDcnlwdG8gLz5cbiAgICAgICAgPFN0YXJ0SW52ZXN0aW5nIC8+XG4gICAgICAgIDxSZWFkeVRvU3RhcnQgLz5cbiAgICAgICAgPE9mZmljaWFsUGFydG5lcnMgLz5cbiAgICA8L1dyYXBwZXI+XG4pXG5cbmV4cG9ydCBkZWZhdWx0IEhvbWVcbiJdfQ== */",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
});

const Home = () => <Wrapper>
        <Head />
        <Hero />
        <WhyReku />
        <WhyCrypto />
        <Features />
        <ExplorePopularCrypto />
        <StartInvesting />
        <ReadyToStart />
        <OfficialPartners />
    </Wrapper>;

export default Home;